import(/* webpackMode: "eager" */ "/tmp/repo/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/repo/components/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/repo/components/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/tmp/repo/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/repo/components/UserProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/tmp/repo/lib/providers.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/repo/node_modules/next/font/google/target.css?{\"path\":\"app/(public)/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/tmp/repo/node_modules/next/font/google/target.css?{\"path\":\"app/(public)/layout.tsx\",\"import\":\"Fraunces\",\"arguments\":[{\"style\":[\"normal\",\"italic\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-display\"}],\"variableName\":\"fontDisplay\"}");
