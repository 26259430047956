"use client"

import { useVisitorStore } from "@/lib/stores/visitorStore";
import Link from "next/link";
import { Button, buttonVariants } from "./ui/button";
import { useEffect, useState } from "react";

export default function CookieBanner() {
    const [loaded, setLoaded] = useState(false)
    const visitorStore = useVisitorStore()

    useEffect(() => {
        setLoaded(true)
    }, [])

    return (
        (loaded && visitorStore.cookieConsent === undefined) ?
            <div className="fixed bottom-0 right-0 z-20 bg-popover text-popover-foreground m-4 md:m-8 p-6 rounded-lg shadow">
                <p className="mb-2">
                    Utilizziamo cookie per migliorare l'esperienza dell'utente. <br />
                    I dati sono anonimi e non verranno condivisi con terze parti.
                </p>
                <Link href={"/cookiePolicy"} className="underline">Cookie policy</Link>
                <div className="flex gap-4 mt-6 justify-center">
                    <Button className="w-full"
                        onClick={() => { visitorStore.setCookieConsent(true) }}
                    >Accetta</Button>
                    <Button className={`w-full text-popover-foreground ${buttonVariants({ variant: "ghost" })}`}
                        onClick={() => { visitorStore.setCookieConsent(false) }}
                    >Rifiuta</Button>
                </div>
            </div>
            : null
    )
}
