"use client"

import { SessionProvider } from "next-auth/react";
import { ReactNode, } from "react";


export default function UserProvider({ children }: { children: ReactNode }) {
    return (
        <SessionProvider session={undefined}>
            {children}
        </SessionProvider>
    )
}
