'use client'

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { usePostHog } from 'posthog-js/react';
import { useSession } from "next-auth/react";

export default function PostHogPageView(): null {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const posthog = usePostHog();
    const { data: session } = useSession();


    useEffect(() => {
        if (!!session?.user.email) {
            posthog.set_config({ persistence: "localStorage+cookie" })
            posthog.identify(session.user.email)
        }
    }, [session?.user.email])

    useEffect(() => {
        // Track pageviews
        if (pathname && posthog) {
            let url = window.origin + pathname
            if (searchParams.toString()) {
                url = url + `?${searchParams.toString()}`
            }
            posthog.capture(
                '$pageview',
                {
                    '$current_url': url,
                }
            )
        }
    }, [pathname, searchParams, posthog])

    return null
}
