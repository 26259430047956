'use client'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import { useVisitorStore } from './stores/visitorStore'
import { useSession } from 'next-auth/react'

export function PHProvider({
    children,
}: {
    children: React.ReactNode
}) {
    const visitorStore = useVisitorStore()

    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
            api_host: process.env.NEXT_PUBLIC_POSTHOG_URL,
            ui_host: "https://eu.posthog.com",
            person_profiles: "identified_only",
            capture_pageview: false, // Disable automatic pageview capture, as we capture manually
            capture_pageleave: true,
            persistence: !!visitorStore.cookieConsent ? 'localStorage+cookie' : 'memory',
            session_recording: {
                maskTextSelector: ".sensitive",
                maskAllInputs: true,
                maskInputFn: (text, element) => {
                    if (element?.classList.contains("sensitive")) {
                        return '*'.repeat(text.length)
                    }
                    return text
                },
            }
        })

    }, []);

    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
