import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import posthog from "posthog-js";

export const useVisitorStore = create<{
    id: string | undefined,
    setId: (id: string) => void
    cookieConsent: boolean | undefined,
    setCookieConsent: (accepted: boolean) => void
}>()(
    persist(
        (set) => ({
            id: undefined,
            setId: (id: string) => set((state) => ({ ...state, id: id })),
            cookieConsent: undefined,
            setCookieConsent: (accepted: boolean) => set((state) => {
                posthog.set_config({ persistence: !!accepted ? 'localStorage+cookie' : 'memory' });
                return { ...state, cookieConsent: accepted }
            }),
        }),
        {
            name: 'visitor-storage',
        }
    )
)
