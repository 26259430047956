"use client"

import { useTheme } from "next-themes"
import { Toaster as Sonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
    const { theme = "system" } = useTheme()

    return (
        <Sonner
            theme={theme as ToasterProps["theme"]}
            className="toaster group"
            toastOptions={{
                unstyled: true,
                classNames: {
                    toast:
                        "toast group flex w-full items-center gap-4 rounded-lg border-0 bg-popover px-4 py-4 font-sans text-popover-foreground shadow sm:gap-8",
                    title: "group-[.toast]:text-sm group-[.toast]:text-popover-foreground",
                    description: "group-[.toast]:text-xs group-[.toast]:text-popover-foreground",
                    actionButton:
                        "font-sans group-[.toast]:rounded-lg group-[.toast]:bg-primary group-[.toast]:px-6 group-[.toast]:py-3 group-[.toast]:text-sm group-[.toast]:font-semibold group-[.toast]:text-primary-foreground",
                    // cancelButton:
                    //     "",
                },
            }}
            {...props}
        />
    )
}

export { Toaster }
